import { upload } from "../services/uploadServices";

const convertToWebP = async (file) => {
  let finalResults = [];
  try {
    for (let a = 0; a < file.length; a++) {
      const result = await new Promise((resolve, reject) => {
        const img = new Image();
        const canvas = document.createElement("canvas");
        const ctx = canvas.getContext("2d");
        img.onload = () => {
          canvas.width = img.width;
          canvas.height = img.height;
          ctx.drawImage(img, 0, 0);
          canvas.toBlob((blob) => {
            if (blob) {
              resolve(blob);
            } else {
              reject(new Error("Failed to convert image to WebP."));
            }
          }, "image/webp");
        };
        img.onerror = () => {
          reject(new Error("Image failed to load."));
        };
        img.src = URL.createObjectURL(file[a]);
      });
      const data = new FormData();
      data.append("file", result, `${file[a].name}.webp`); // Append the WebP file
      const res = await upload(data);
      if (res && res.data && res.data.status === 200) {
        finalResults.push(res.data.files[0]);
      }
    }
    return finalResults;
  } catch (error) {
    console.error("Error converting image to WebP:", error);
    return false;
  }
};

export default convertToWebP;
