import { MEDIALARGE, MEDIAMEDIUM, MEDIAQUERY, MEDIASMALL } from "../variable";

const mediaQuery = (tempState, key, value, widthDevice) => {
  switch (widthDevice) {
    case MEDIALARGE:
      return value;
    case MEDIAMEDIUM:
      if (!key.includes(".")) {
        if (tempState.mediaQueri768 && tempState.mediaQueri768[key]) {
          if (!key.includes(".")) {
            if (key === "borderRadius") {
              let finalValue = borderRadiusFilter(tempState, widthDevice);
              return finalValue;
            } else {
              return tempState.mediaQueri768[key];
            }
          } else {
            return tempState.mediaQueri768[key[0]][key[1]];
          }
        } else {
        }
      } else {
        let newKey = key.split(".");
        if (tempState.mediaQueri768 && tempState.mediaQueri768[newKey[0]]) {
          return tempState.mediaQueri768[newKey[0]][newKey[1]];
        } else {
          return value;
        }
      }

    case MEDIASMALL:
      if (!key.includes(".")) {
        if (tempState.mediaQueri360 && tempState.mediaQueri360[key]) {
          if (!key.includes(".")) {
            if (key === "borderRadius") {
              let finalValue = borderRadiusFilter(tempState, widthDevice);
              return finalValue;
            } else {
              return tempState.mediaQueri360[key];
            }
          } else {
            return tempState.mediaQueri360[key[0]][key[1]];
          }
        } else {
          if (tempState.mediaQueri768 && tempState.mediaQueri768[key]) {
            return tempState.mediaQueri768[key];
          } else {
            return value;
          }
        }
      } else {
        let newKey = key.split(".");
        if (tempState.mediaQueri360 && tempState.mediaQueri360[newKey[0]]) {
          return tempState.mediaQueri360[newKey[0]][newKey[1]];
        } else {
          if (tempState.mediaQueri768 && tempState.mediaQueri768[newKey[0]]) {
            return tempState.mediaQueri768[newKey[0]][newKey[1]];
          } else {
            return value;
          }
        }
      }
  }
};

/**
 * border radius checking start
 */
const checkingRadius = (object, key, widthDevice) => {
  let deviceDefault = object[key];
  switch (widthDevice) {
    case MEDIALARGE:
      if (object[key]) {
        deviceDefault = object[key];
      } else {
        deviceDefault = 0;
      }
      break;
    case MEDIAMEDIUM:
      if (object?.mediaQueri768 && object?.mediaQueri768[key]) {
        deviceDefault = object?.mediaQueri768[key];
      } else {
        deviceDefault = object[key];
      }
      break;
    case MEDIASMALL:
      if (object?.mediaQueri360 && object?.mediaQueri360[key]) {
        deviceDefault = object?.mediaQueri360[key];
      } else if (object?.mediaQueri768 && object?.mediaQueri768[key]) {
        deviceDefault = object?.mediaQueri768[key];
      } else {
        deviceDefault = object[key];
      }
      break;
  }
  return deviceDefault;
};
const borderRadiusFilter = (object, widthDevice) => {
  let allValue = [];
  let borderRadius = checkingRadius(object, "borderRadius", widthDevice) || 0;
  let borderTopLeftRadius =
    checkingRadius(object, "borderTopLeftRadius", widthDevice) || 0;
  let borderTopRightRadius =
    checkingRadius(object, "borderTopRightRadius", widthDevice) || 0;
  let borderBottomLeftRadius =
    checkingRadius(object, "borderBottomLeftRadius", widthDevice) || 0;
  let borderBottomRightRadius =
    checkingRadius(object, "borderBottomRightRadius", widthDevice) || 0;
  let finalValue = borderRadius;
  if (
    borderTopLeftRadius !== 0 ||
    borderTopRightRadius !== 0 ||
    borderBottomLeftRadius !== 0 ||
    borderBottomRightRadius !== 0
  ) {
    allValue.push(`${borderTopLeftRadius}px`);
    allValue.push(`${borderTopRightRadius}px`);
    allValue.push(`${borderBottomLeftRadius}px`);
    allValue.push(`${borderBottomRightRadius}px`);
    finalValue = allValue.join(" ");
  }
  return finalValue;
};

const borderRadiusMediaQuery = (tempState, key, object, widthDevice) => {
  let finalValue = borderRadiusFilter(object, widthDevice);
  return mediaQuery(tempState, key, finalValue, widthDevice);
};

const isAdvancedRadius = (data) => {
  if (
    data.borderTopLeftRadius !== undefined ||
    data.borderTopRightRadius !== undefined ||
    data.borderBottomLeftRadius !== undefined ||
    data.borderBottomRightRadius !== undefined
  ) {
    if (
      data.borderTopLeftRadius !== "0px" ||
      data.borderTopRightRadius !== "0px" ||
      data.borderBottomLeftRadius !== "0px" ||
      data.borderBottomRightRadius !== "0px"
    ) {
      return true;
    } else {
      return false;
    }
  } else {
    return false;
  }
};

const borderRadiusColomMediaQuery = (object, widthDevice) => {
  let result = borderRadiusFilter(object, widthDevice);
  return result;
};

const clearBorderRadius = (masterData) => {
  let tempState = { ...masterData };
  tempState.borderRadius = 0;
  tempState.borderTopLeftRadius = 0;
  tempState.borderTopRightRadius = 0;
  tempState.borderBottomLeftRadius = 0;
  tempState.borderBottomRightRadius = 0;
  if (tempState[`${MEDIAQUERY}${MEDIASMALL}`]) {
    tempState[`${MEDIAQUERY}${MEDIASMALL}`].borderTopLeftRadius = 0;
    tempState[`${MEDIAQUERY}${MEDIASMALL}`].borderTopRightRadius = 0;
    tempState[`${MEDIAQUERY}${MEDIASMALL}`].borderBottomLeftRadius = 0;
    tempState[`${MEDIAQUERY}${MEDIASMALL}`].borderBottomRightRadius = 0;
    tempState[`${MEDIAQUERY}${MEDIASMALL}`].borderRadius = 0;
  }
  if (tempState[`${MEDIAQUERY}${MEDIAMEDIUM}`]) {
    tempState[`${MEDIAQUERY}${MEDIAMEDIUM}`].borderTopLeftRadius = 0;
    tempState[`${MEDIAQUERY}${MEDIAMEDIUM}`].borderTopRightRadius = 0;
    tempState[`${MEDIAQUERY}${MEDIAMEDIUM}`].borderBottomLeftRadius = 0;
    tempState[`${MEDIAQUERY}${MEDIAMEDIUM}`].borderBottomRightRadius = 0;
    tempState[`${MEDIAQUERY}${MEDIAMEDIUM}`].borderRadius = 0;
  }
  return tempState;
};

/**
 * border radius checking end
 */

export default mediaQuery;
export {
  borderRadiusMediaQuery,
  borderRadiusColomMediaQuery,
  isAdvancedRadius,
  clearBorderRadius,
};
