import React, { useState, useEffect } from "react";
import "react-color-palette/css";
import { Form, Tabs, Tab } from "react-bootstrap";
import RangeSlider from "../form/Rangeslider";
import labelCSS from "../../helpers/labelCss";
import ColorComp from "../form/ColorComp";
import tinycolor from "tinycolor2";
import BorderComp from "../form/BorderComp";
import uploadHelper from "../../helpers/uploadHelper";
import Loader from "../Loader";
import {
  MEDIALARGE,
  MEDIAMEDIUM,
  MEDIAQUERY,
  MEDIASMALL,
  SIZEMEDIUM,
  SIZESMALL,
  TRANSCOLOR,
} from "../../variable";
import mediaQuery from "../../helpers/mediaQueryHelper";
import BorderRadiusComp from "../form/BorderRadiusComp";
import ButtonSpaceComp from "../ButtonSpaceComp";
import { convertColor } from "../../helpers/colorHelper";
import InputComp from "../form/InputComp";
import InputUploadComp from "../form/Input/InputUploadComp";
import {
  enableFlexMode,
  enablePositionMode,
  injectColomProperties,
} from "../../helpers/builderHelper";
import convertToWebP from "../../helpers/webPHelper";
const ColomView = ({ data, changeColomData, widthDevice, isResponsive }) => {
  const [enableFlex, setEnableFlex] = useState(false);
  const [enablePosition, setEnablePosition] = useState(false);
  const [isBorderRadiusAdvanced, setIsBorderRadiusAdvanced] = useState(false);

  /**
   * to support old theme
   * we inject some news properties
   */
  data = injectColomProperties(data);

  const listReponsiveKey = [
    "overflow",
    "width",
    "flexDirection",
    "marginTop",
    "marginLeft",
    "marginRight",
    "marginBottom",
    "paddingTop",
    "paddingRight",
    "paddingBottom",
    "paddingLeft",
    "backgroundColor",
    "backgroundSize",
    "opacity",
    "widthType",
    "flex",
    "borderStyle",
  ];
  const listAdvancedBorderRadius = [
    "borderTopLeftRadius",
    "borderTopRightRadius",
    "borderBottomLeftRadius",
    "borderBottomRightRadius",
  ];

  const listOverflow = ["hidden", "unset"];
  const listBackground = ["color", "image", "slide"];
  const listGradient = ["solid", "linear"];
  const listBgType = ["cover", "repeat"];
  const listBooleanText = ["yes", "no"];
  const listPosition = ["static", "relative"];
  const listFlexType = ["flex", "custom"];
  const listFlexDirection = ["column", "row"];

  const [colData, setColData] = useState(data);
  const [widthDevice2, setWidthDevice2] = useState(widthDevice);
  const [masterData, setMasterData] = useState(data);
  const [isUploading, setIsUploading] = useState(false);
  const [key, setKey] = useState("main");
  const [isActive, setIsActive] = useState(false);
  const [showColor, setShowColor] = useState(false);
  const [foreground, setForgound] = useState(data?.cover !== "" ? "yes" : "no");
  const [isReady, setIsReady] = useState(false);

  useEffect(() => {
    console.log("masterData", masterData);
  }, [masterData]);

  useEffect(() => {
    setEnableFlex(enableFlexMode(data, isResponsive));
    setEnablePosition(enablePositionMode(data));
  }, [data, isResponsive]);

  const changeColDataGlobal = (key, value) => {
    setColData((prev) => {
      return {
        ...prev,
        [key]: value,
      };
    });
  };

  const opacityChange = (key, value) => {
    if (colData.linear === "linear") {
      if (
        colData?.cover?.backgroundColor?.trim() !== "" &&
        colData?.cover?.backgroundColor !== TRANSCOLOR &&
        colData?.cover2?.backgroundColor?.trim() !== "" &&
        colData?.cover2?.backgroundColor !== TRANSCOLOR
      ) {
        let bgColor = TRANSCOLOR;
        if (colData.cover.backgroundColor) {
          bgColor = convertColor(colData.cover.backgroundColor, value);
        }
        let bgColor2 = TRANSCOLOR;
        if (colData?.cover2?.backgroundColor) {
          bgColor2 = convertColor(colData.cover2.backgroundColor, value);
        }
        setColData((prev) => {
          return {
            ...prev,
            [key]: value,
            cover: {
              backgroundColor: bgColor,
            },
            cover2: {
              backgroundColor: bgColor2,
            },
          };
        });
      } else {
        if (
          colData?.cover?.backgroundColor?.trim() !== "" &&
          colData?.cover?.backgroundColor !== TRANSCOLOR
        ) {
          let bgColor = convertColor(colData.cover.backgroundColor, value);
          setColData((prev) => {
            return {
              ...prev,
              [key]: value,
              cover: {
                backgroundColor: bgColor,
              },
              cover2: {
                backgroundColor: TRANSCOLOR,
              },
            };
          });
        } else {
          if (
            colData?.cover2?.backgroundColor?.trim() !== "" &&
            colData?.cover2?.backgroundColor !== TRANSCOLOR
          ) {
            let bgColor = convertColor(colData.cover2.backgroundColor, value);
            setColData((prev) => {
              return {
                ...prev,
                [key]: value,
                cover: {
                  backgroundColor: TRANSCOLOR,
                },
                cover2: {
                  backgroundColor: bgColor,
                },
              };
            });
          }
        }
      }
    } else {
      if (
        colData?.cover?.backgroundColor !== TRANSCOLOR &&
        colData?.cover?.backgroundColor?.trim() !== ""
      ) {
        let bgColor = convertColor(colData.cover.backgroundColor, value);
        setColData((prev) => {
          return {
            ...prev,
            [key]: value,
            cover: {
              backgroundColor: bgColor,
            },
          };
        });
      }
    }
  };

  const cbValue = (data) => {
    let key = labelCSS(data.key);
    let value = data.value;
    if (data.key === "opacity") {
      value = value / 10;
    }
    if (widthDevice === MEDIALARGE) {
      if (key === "opacity") {
        opacityChange("opacity", value);
      } else {
        setColData((prev) => {
          return {
            ...prev,
            [key]: value,
          };
        });
      }
    }
    if (widthDevice === MEDIAMEDIUM || widthDevice === MEDIASMALL) {
      let widthDeviceStr = SIZEMEDIUM;
      if (widthDevice === MEDIASMALL) {
        widthDeviceStr = SIZESMALL;
      }
      let temp = { ...colData };
      if (colData[`${MEDIAQUERY}${widthDeviceStr}`]) {
        let tempNewObject = temp[`${MEDIAQUERY}${widthDeviceStr}`];
        let newObject = {
          [key]: value,
        };
        let newJoin = { ...tempNewObject, ...newObject };
        temp[`${MEDIAQUERY}${widthDeviceStr}`] = newJoin;
        setColData(temp);
      } else {
        temp[`${MEDIAQUERY}${widthDeviceStr}`] = {
          [key]: value,
        };
        setColData(temp);
      }
    }
  };

  useEffect(() => {
    if (foreground === "no") {
      setShowColor(false);
      setColData((prev) => {
        return {
          ...prev,
          cover: "",
        };
      });
    } else {
      setShowColor(true);
      if (data.linear === "solid") {
        setColData((prev) => {
          return {
            ...prev,
            cover: {
              backgroundColor: data?.cover?.backgroundColor
                ? data?.cover?.backgroundColor
                : "#fff",
              opacity: data?.cover?.opacity ? data?.cover?.opacity : 1,
            },
          };
        });
      } else {
        setColData((prev) => {
          return {
            ...prev,
            cover: {
              backgroundColor: data?.cover?.backgroundColor
                ? data?.cover?.backgroundColor
                : "#fff",
              opacity: data?.cover?.opacity ? data?.cover?.opacity : 1,
            },
            cover2: {
              backgroundColor: data?.cover2?.backgroundColor
                ? data?.cover2?.backgroundColor
                : "#fff",
              opacity: data?.cover2?.opacity ? data?.cover2?.opacity : 1,
            },
          };
        });
      }
    }
  }, [foreground]);

  const noCoverColorBG = (level) => {
    let tempdata = { ...colData };
    if (level === "primary") {
      tempdata.cover.backgroundColor = TRANSCOLOR;
      tempdata.cover.rgb = "";
    } else {
      tempdata.cover2.backgroundColor = TRANSCOLOR;
      tempdata.cover2.rgb = "";
    }
    setColData(tempdata);
  };

  useEffect(() => {
    let tempState = { ...masterData };
    tempState.borderRadius = 0;
    for (let a = 0; a < listAdvancedBorderRadius.length; a++) {
      tempState[listAdvancedBorderRadius[a]] = 0;
    }
    if (tempState[`${MEDIAQUERY}${MEDIASMALL}`]) {
      for (let a = 0; a < listAdvancedBorderRadius.length; a++) {
        tempState[`${MEDIAQUERY}${MEDIASMALL}`][
          listAdvancedBorderRadius[a]
        ] = 0;
      }
      tempState[`${MEDIAQUERY}${MEDIASMALL}`].borderRadius = 0;
    }
    if (tempState[`${MEDIAQUERY}${MEDIAMEDIUM}`]) {
      for (let a = 0; a < listAdvancedBorderRadius.length; a++) {
        tempState[`${MEDIAQUERY}${MEDIAMEDIUM}`][
          listAdvancedBorderRadius[a]
        ] = 0;
      }
      tempState[`${MEDIAQUERY}${MEDIAMEDIUM}`].borderRadius = 0;
    }
    setColData(tempState);
  }, [isBorderRadiusAdvanced]);

  const calculateSetting = async (widthDevice) => {
    let tempState = { ...colData };
    for (let a = 0; a < listReponsiveKey.length; a++) {
      tempState[listReponsiveKey[a]] = mediaQuery(
        tempState,
        listReponsiveKey[a],
        tempState[listReponsiveKey[a]],
        widthDevice
      );
    }
    if (isBorderRadiusAdvanced) {
      tempState.borderRadius = 0;
      for (let a = 0; a < listAdvancedBorderRadius.length; a++) {
        tempState[listAdvancedBorderRadius[a]] = mediaQuery(
          tempState,
          listAdvancedBorderRadius[a],
          tempState[listAdvancedBorderRadius[a]],
          widthDevice
        );
      }
    } else {
      tempState.borderRadius = mediaQuery(
        tempState,
        "borderRadius",
        tempState.borderRadius,
        widthDevice
      );
      for (let a = 0; a < listAdvancedBorderRadius.length; a++) {
        tempState[listAdvancedBorderRadius[a]] = 0;
      }
    }

    setMasterData(tempState);
    setTimeout(() => {
      setIsReady(true);
    }, 500);
  };

  useEffect(() => {
    setIsReady(false);
    setTimeout(() => {
      calculateSetting(widthDevice2);
    }, 500);
  }, [widthDevice2]);

  useEffect(() => {
    if (widthDevice !== widthDevice2) {
      setWidthDevice2(widthDevice);
    }
  }, [widthDevice]);

  const uploadfileMultiple = async (event, type) => {
    try {
      setIsUploading(true);
      let webP = await convertToWebP(event.target.files);
      var datatemp = { ...colData };
      if (
        datatemp.image_slide.length > 0 &&
        datatemp.image_slide[0].img ===
          "https://icon-library.com/images/gallery-icon-png/gallery-icon-png-8.jpg"
      ) {
        var tempImages = [];
      } else {
        var tempImages = datatemp.image_slide;
      }

      let result = await uploadHelper(event, event.target.files.length);
      if (result) {
        for (let a = 0; a < result.length; a++) {
          tempImages.push({
            img: result[a].widthImage,
            imagewebp: webP[a],
          });
        }
        setColData((prev) => {
          return {
            ...prev,
            image_slide: tempImages,
          };
        });
      }
      setIsUploading(false);
    } catch {
      alert("gagal upload file tidak compatible");
      setIsUploading(false);
    }
  };

  const uploadfile = async (event, type) => {
    try {
      setIsUploading(true);
      let webP = await convertToWebP(event.target.files);
      let result = await uploadHelper(event);
      if (result) {
        setColData((prev) => {
          return {
            ...prev,
            backgroundImageWebp: webP[0] ? webP[0] : "",
            backgroundImage: result[0].widthImage,
            backgroundColor: "",
            backgroundSize: "cover",
          };
        });
      }
      setIsUploading(false);

      setIsUploading(false);
    } catch {
      alert("file tidak compatible");
      setIsUploading(false);
    }
  };

  const setCover = (level, value) => {
    if (colData.opacity === undefined) {
      colData.opacity = 10;
    }
    if (level === "primary") {
      setColData((prev) => {
        return {
          ...prev,
          cover: {
            backgroundColor: `rgba(${value.rgb.r}, ${value.rgb.g}, ${value.rgb.b}, ${colData.opacity})`,
            rgb: `rgb(${value.rgb.r}, ${value.rgb.g}, ${value.rgb.b}`,
          },
        };
      });
    } else {
      setColData((prev) => {
        return {
          ...prev,
          cover2: {
            backgroundColor: `rgba(${value.rgb.r}, ${value.rgb.g}, ${value.rgb.b}, ${colData.opacity})`,
            rgb: `rgb(${value.rgb.r}, ${value.rgb.g}, ${value.rgb.b}`,
          },
        };
      });
    }
  };

  const changeBackgroundSize = (value) => {
    setColData((prev) => {
      return {
        ...prev,
        backgroundSize: value,
      };
    });
  };

  const removeImage = (index) => {
    var datatemp = { ...data };
    if (datatemp.image_slide.length > 1) {
      datatemp.image_slide.splice(index, 1);
      datatemp.images378.splice(index, 1);
      datatemp.images678.splice(index, 1);
      setColData(datatemp);
    }
  };

  const changeForground = (value) => {
    if (value === "yes" || value === true) {
      setShowColor(true);
      setColData((prev) => {
        return {
          ...prev,
          cover: {
            backgroundColor: TRANSCOLOR,
            opacity: 1,
          },
        };
      });
    } else {
      setShowColor(false);
      setColData((prev) => {
        return {
          ...prev,
          cover: "",
        };
      });
    }

    setForgound(value);
  };

  useEffect(() => {
    setColData((prev) => {
      return {
        ...prev,
        foreground: foreground === "no" ? false : true,
      };
    });
  }, [foreground]);

  const setBgColortoBackground = (level, value) => {
    if (isActive) {
      if (level === "primary") {
        setColData((prev) => {
          return {
            ...prev,
            backgroundColor: `rgb(${value.rgb.r}, ${value.rgb.g}, ${value.rgb.b})`,
          };
        });
      } else {
        setColData((prev) => {
          return {
            ...prev,
            backgroundColor2: `rgb(${value.rgb.r}, ${value.rgb.g}, ${value.rgb.b})`,
          };
        });
      }
    }
    setTimeout(() => {
      setIsActive(true);
    }, 100);
    //
  };

  useEffect(() => {
    if (isReady) {
      changeColomData(colData);
      setTimeout(() => {
        calculateSetting(widthDevice2);
      }, 10);
    }
  }, [colData]);

  const changeBorderColor = (value) => {
    setColData((prev) => {
      return {
        ...prev,
        isBorderTransparent: false,
        borderColor: value.hex,
      };
    });
  };

  const changeBorder = (value) => {
    setColData((prev) => {
      return {
        ...prev,
        borderWidth: value.value,
      };
    });
  };

  const noColorBG = () => {
    setColData((prev) => {
      return {
        ...prev,
        backgroundColor: TRANSCOLOR,
      };
    });
  };

  const changeBackground = (value) => {
    if (value !== "image") {
      setColData((prev) => {
        return {
          ...prev,
          backgroundType: value,
          cover: "",
          backgroundImage: "",
        };
      });
    } else {
      setColData((prev) => {
        return {
          ...prev,
          backgroundType: value,
          cover: "",
        };
      });
    }
    setForgound("no");
  };

  const boderRadiusAdvancedMode = (val) => {
    setIsBorderRadiusAdvanced(val);
  };

  const ImageSection = () => {
    return (
      <>
        {isUploading ? (
          <Loader></Loader>
        ) : (
          <>
            {" "}
            {data.backgroundImage && (
              <img src={data.backgroundImage} width="100%" height="200px" />
            )}
          </>
        )}
        <Form.Group controlId="name" className="form-control-dark">
          {/* <Form.Label><span style={{ color: 'red' }}>*</span>Background</Form.Label> */}
          <InputUploadComp
            id="custom-file"
            label="Custom file input"
            name="input"
            type="file"
            className="file"
            data-show-upload="false"
            data-show-caption="true"
            custom
            onChange={($e) => {
              uploadfile($e, "address");
            }}
          />
        </Form.Group>
        <InputComp
          type="select"
          label="Background Type"
          value={data.backgroundSize}
          options={listBgType}
          onChange={changeBackgroundSize}
        />
        <InputComp
          type="select"
          label="Foreground"
          value={foreground}
          options={listBooleanText}
          onChange={changeForground}
        />
        {foreground === "yes" && (
          <>
            <InputComp
              type="select"
              label="Linear"
              value={colData.linear}
              options={listGradient}
              onChange={(value) => {
                cbValue({
                  key: "linear",
                  value: value,
                });
              }}
            />
            {showColor && (
              <ColorComp
                label="Color"
                colorCB={(value) => setCover("primary", value)}
                primaryColor={tinycolor(
                  colData.cover.backgroundColor
                ).toHexString()}
                showNoColor={true}
                noColor={() => noCoverColorBG("primary")}
              ></ColorComp>
            )}
            {showColor && colData.linear === "linear" && (
              <ColorComp
                label="Color"
                colorCB={(value) => setCover("secondary", value)}
                primaryColor={tinycolor(
                  colData?.cover2?.backgroundColor
                ).toHexString()}
                showNoColor={true}
                noColor={() => noCoverColorBG("secondary")}
              ></ColorComp>
            )}

            {showColor && (
              /**
               * because opacity is less than 1
               * multiply by 10
               * so rangeslider can active
               */
              <RangeSlider
                value={colData.opacity * 10}
                label="opacity"
                cbValue={cbValue}
                maxvalue="10"
              ></RangeSlider>
            )}
          </>
        )}
      </>
    );
  };

  const SlideSection = () => {
    return (
      <>
        <Form.Group controlId="name">
          <Form.Label>
            <span style={{ color: "red" }}>*</span> Gambar Produk
          </Form.Label>
          <Form.Control
            id="custom-file"
            label="Custom file input"
            name="input2[]"
            type="file"
            className="file"
            data-show-upload="false"
            data-show-caption="true"
            custom
            multiple
            onChange={($e) => {
              uploadfileMultiple($e);
            }}
          />
          <div>
            <i>support jpg/png max 5mb</i>
          </div>
          {data.image_slide &&
            data.image_slide.map((img, index) => {
              return (
                <div style={{ display: "flex" }} key={index}>
                  <div style={{ flex: 1, marginBottom: "10px" }}>
                    <img src={img.img} className="img-fluid" />
                    {data.image_slide.length > 1 && (
                      <div
                        style={{
                          width: "100%",
                          height: "20px",
                          background: "#f5f5f5",
                          zIndex: 1,
                          textAlign: "center",
                          cursor: "pointer",
                        }}
                        onClick={() => removeImage(index)}
                      >
                        <i
                          className="fas fa-times"
                          style={{
                            zIndex: 100,
                            fontSize: 16,
                            color: "red",
                            cursor: "pointer",
                          }}
                        ></i>
                      </div>
                    )}
                  </div>
                </div>
              );
            })}
        </Form.Group>
        <InputComp
          type="select"
          label="Foreground"
          value={foreground}
          options={listBooleanText}
          onChange={changeForground}
        />
        {foreground === "yes" && (
          <>
            <InputComp
              type="select"
              label="Linear"
              value={colData.linear}
              options={listGradient}
              onChange={(value) => {
                cbValue({
                  key: "linear",
                  value: value,
                });
              }}
            />
            {showColor && (
              <ColorComp
                label="Color"
                colorCB={(value) => setCover("primary", value)}
                primaryColor={tinycolor(
                  colData?.cover?.backgroundColor
                ).toHexString()}
                showNoColor={true}
                noColor={() => noCoverColorBG("primary")}
              ></ColorComp>
            )}
            {showColor && colData.linear === "linear" && (
              <ColorComp
                label="Color"
                colorCB={(value) => setCover("secondary", value)}
                primaryColor={tinycolor(
                  colData?.cover2?.backgroundColor
                ).toHexString()}
                showNoColor={true}
                noColor={() => noCoverColorBG("secondary")}
              ></ColorComp>
            )}
            {showColor && (
              <RangeSlider
                value={colData.opacity}
                label="opacity"
                cbValue={cbValue}
                maxvalue="10"
              ></RangeSlider>
            )}
          </>
        )}
      </>
    );
  };

  const ColorSection = () => {
    return (
      <>
        <InputComp
          type="select"
          label="Linear"
          value={colData.linear}
          options={listGradient}
          onChange={(value) => {
            cbValue({
              key: "linear",
              value: value,
            });
          }}
        />
        <ColorComp
          label="Background"
          colorCB={(value) => setBgColortoBackground("primary", value)}
          primaryColor={tinycolor(data?.backgroundColor).toHexString()}
          showNoColor={true}
          noColor={noColorBG}
        ></ColorComp>
        {data.linear === "linear" && (
          <>
            <ColorComp
              label="Background"
              colorCB={(value) => setBgColortoBackground("secondary", value)}
              primaryColor={tinycolor(data?.backgroundColor2).toHexString()}
              showNoColor={true}
              noColor={noColorBG}
            ></ColorComp>
          </>
        )}
      </>
    );
  };

  return (
    <div style={{ textAlign: "left" }}>
      <Tabs
        id="controlled-tab-example"
        activeKey={key}
        onSelect={(k) => setKey(k)}
        className="mb-3"
      >
        <Tab eventKey="main" title="main">
          {isReady && (
            <div>
              <InputComp
                type="select"
                label="Background"
                value={data.backgroundType}
                options={listBackground}
                onChange={changeBackground}
              />
              {data.backgroundType === "color" && <ColorSection></ColorSection>}
              {data.backgroundType === "image" && <ImageSection></ImageSection>}
              {/* {background === "color" && <RangeSlider value={colData.opacity} label="opacity" cbValue={cbValue} maxvalue="10"></RangeSlider>} */}
              {data.backgroundType === "slide" && <SlideSection></SlideSection>}
              <ColorComp
                label="Border"
                colorCB={changeBorderColor}
                primaryColor={colData.borderColor}
                showNoColor={false}
              ></ColorComp>

              {enablePosition && (
                <>
                  <InputComp
                    type="select"
                    label="Position"
                    value={data.position}
                    options={listPosition}
                    onChange={(value) => {
                      changeColDataGlobal("position", value);
                    }}
                  />

                  {colData.position === "relative" && (
                    <>
                      <RangeSlider
                        value={colData.width}
                        label="Width"
                        cbValue={cbValue}
                        maxvalue="800"
                      ></RangeSlider>
                    </>
                  )}
                </>
              )}
              {enableFlex && (
                <>
                  <InputComp
                    isRequired={true}
                    type="select"
                    label="Direction"
                    value={masterData.flexDirection}
                    options={listFlexDirection}
                    onChange={(value) =>
                      cbValue({
                        key: "flexDirection",
                        value: value,
                      })
                    }
                  />
                </>
              )}
              {!enableFlex && (
                <>
                  <InputComp
                    type="select"
                    label="Flex/custom"
                    value={masterData.widthType}
                    options={listFlexType}
                    onChange={(value) => {
                      cbValue({
                        key: "widthType",
                        value: value,
                      });
                    }}
                  />

                  {colData.widthType === "flex" && (
                    <InputComp
                      type="number"
                      label="Flex"
                      value={masterData.flex}
                      onChange={(value) => {
                        cbValue({
                          key: "flex",
                          value: value,
                        });
                      }}
                    />
                  )}

                  {colData.widthType === "custom" && (
                    <div style={{ display: "flex" }}>
                      <div style={{ flex: 2 }}>
                        <InputComp
                          type="number"
                          label="Width"
                          value={masterData.width}
                          onChange={(value) => {
                            cbValue({
                              key: "width",
                              value: value,
                            });
                          }}
                        />
                      </div>
                    </div>
                  )}
                </>
              )}
              <InputComp
                type="select"
                label="Overflow"
                value={masterData.overflow}
                options={listOverflow}
                onChange={(value) => {
                  cbValue({
                    key: "overflow",
                    value: value,
                  });
                }}
              />

              <BorderComp
                defaultValue={masterData.borderStyle}
                changeValue={(value) => {
                  cbValue({
                    key: "borderStyle",
                    value: value,
                  });
                }}
              ></BorderComp>
            </div>
          )}
          <ButtonSpaceComp></ButtonSpaceComp>
        </Tab>
        <Tab eventKey="setting" title="setting">
          {isReady && (
            <>
              <BorderRadiusComp
                value={masterData.borderRadius}
                label="Border Radius"
                cbValue={cbValue}
                maxvalue="100"
                advancedValue={{
                  borderTopLeftRadius: masterData.borderTopLeftRadius,
                  borderTopRightRadius: masterData.borderTopRightRadius,
                  borderBottomLeftRadius: masterData.borderBottomLeftRadius,
                  borderBottomRightRadius: masterData.borderBottomRightRadius,
                }}
                isAdvancedMode={isBorderRadiusAdvanced}
                adVancedModeCB={boderRadiusAdvancedMode}
              ></BorderRadiusComp>
              <RangeSlider
                value={masterData.marginTop}
                label="Margin Top"
                cbValue={cbValue}
                maxvalue="100"
              ></RangeSlider>
              <RangeSlider
                value={masterData.marginRight}
                label="Margin Right"
                cbValue={cbValue}
                maxvalue="100"
              ></RangeSlider>
              <RangeSlider
                value={masterData.marginBottom}
                label="Margin Bottom"
                cbValue={cbValue}
                maxvalue="100"
              ></RangeSlider>
              <RangeSlider
                value={masterData.marginLeft}
                label="Margin Left"
                cbValue={cbValue}
                maxvalue="100"
              ></RangeSlider>
              <RangeSlider
                value={masterData.paddingTop}
                label="Padding Top"
                cbValue={cbValue}
                maxvalue="100"
              ></RangeSlider>
              <RangeSlider
                value={masterData.paddingRight}
                label="Padding Right"
                cbValue={cbValue}
                maxvalue="100"
              ></RangeSlider>
              <RangeSlider
                value={masterData.paddingBottom}
                label="Padding Bottom"
                cbValue={cbValue}
                maxvalue="100"
              ></RangeSlider>
              <RangeSlider
                value={masterData.paddingLeft}
                label="Padding Left"
                cbValue={cbValue}
                maxvalue="100"
              ></RangeSlider>
              {/* <RangeSlider value={masterData.borderRadius} label="Border Radius" cbValue={cbValue} maxvalue="100"></RangeSlider> */}
              <RangeSlider
                value={masterData.borderWidth}
                label="Border Width"
                cbValue={changeBorder}
                maxvalue="100"
              ></RangeSlider>
            </>
          )}
          <ButtonSpaceComp></ButtonSpaceComp>
        </Tab>
        <Tab eventKey="menu" title="menu">
          <Form.Group controlId="name">
            <Form.Label>
              <span style={{ color: "red" }}></span>Tag Name
            </Form.Label>
            <Form.Control
              type="text"
              placeholder=""
              value={data.tag}
              onChange={($e) => changeColDataGlobal("tag", $e.target.value)}
            ></Form.Control>
          </Form.Group>
          {/* <FontIconComp iconclassName={data.icon} iconClassCB={changeIcon}></FontIconComp> */}
          <ButtonSpaceComp></ButtonSpaceComp>
        </Tab>
      </Tabs>
    </div>
  );
};

export default ColomView;
